import {
  useStyles,
  useWindowSize,
  GlobalCard,
  usePathname,
  WCInlineInputFilter,
  ModalComponent,
} from "@mfe/js-common-ave-uiutils";

import {
  Button,
  Box,
  Flex,
  LoadingOverlay,
  Grid,
  Text,
  Center,
} from "@mantine/core";

import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { LuChevronDown } from "react-icons/lu";
import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import {
  getAccessTokenApi,
  fetchTravelRequestApproverData,
  fetchTravelStatusData,
  fetchTravelRequestManagerData,
  fetchApproverActiveData,
  fetchUserAdditionalInfoByTravel,
  fetchTravelRequestApproveTripMasive,
  fetchTravelRequestApproveLegMassive,
} from "@mfe/ts-common-ave-utilitaries";
import OutpatientcareRequestCardDetail from "../views/outpatientcareRequestCardDetail.component";
import Tabs from "../components/tabs.component";
import React, { useEffect, useState } from "react";
import { navigateToUrl } from "single-spa";
import TopFilterSection from "../components/topFilterSection.component";

export default function Requests({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <RequestsComponent organization={organization} />
    </MsalProvider>
  );
}

export const RequestsComponent = ({ organization }) => {
  const { classes } = useStyles();
  const pathname = usePathname();
  const isSm = useWindowSize("sm");

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [tokenApi, setTokenApi] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [clearFilters, setClearFilters] = useState(false);
  const [checked, setChecked] = useState(false);
  const [approveAll, setApproveAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [dataUser, setDataUser] = useState(userData);
  const [opened, { toggle }] = useDisclosure(false);
  const [openedError, { open, close }] = useDisclosure(false);
  const [hasMassiveLegalization, setHasMassiveLegalization] = useState(false);
  const [checkedLeg, setCheckedLeg] = useState(false);
  const [approveAllLeg, setApproveAllLeg] = useState([]);

  const [modalContent, setModalContent] = useState({
    content: null,
    showButtons: false,
    onClick: () => {},
  });

  const [data, setData] = useState({
    requestData: [],
    status: [],
    approverActive: null,
  });

  const form = useForm({
    initialValues: {
      statusFilter: [],
      idFilter: "",
      idTraveler: "",
      refectTripConfirmTravel: "yes",
      refectTripConfirmContinue: "yes",
    },
  });

  const filterData = {
    viajeId: form.values.idFilter || "0",
    estadosviaje: form.values.statusFilter,
    codigoEmpleadoviajero: form.values.idTraveler || null,
    esAuxilio: true,
  };

  useEffect(() => {
    if (form.values.idFilter.length > 0) {
      form.setFieldValue("idTraveler", "");
    }
  }, [form.values.idFilter]);

  useEffect(() => {
    if (form.values.idTraveler.length > 0) {
      form.setFieldValue("idFilter", "");
    }
  }, [form.values.idTraveler]);

  useEffect(() => {
    form.setValues((prev) => ({
      ...prev,
      idFilter: prev.idFilter.replace(/[^0-9]/g, ""),
      idTraveler: prev.idTraveler.replace(/[^0-9]/g, ""),
    }));
  }, [form.values.idFilter, form.values.idTraveler]);

  const dinamicRoutes = [
    {
      name: "Auxilios por aprobar",
      route: "auxilios-por-aprobar",
      fetchData: () =>
        fetchTravelRequestApproverData(
          tokenApi,
          dataUser.datosUsuario.codigoEmpleado,
          setData,
          pageNumber,
          setLoading,
          {
            ...filterData,
            estadosViaje:
              filterData.estadosviaje.length > 0
                ? filterData.estadosviaje
                : ["PENDAPROBO", "PRAPROBADO", "LEGALIZENV"],
          }
        ),
    },

    {
      name: "Auxilios Gestionados",
      route: "auxilios-gestionados",
      fetchData: () =>
        fetchTravelRequestManagerData(
          tokenApi,
          dataUser.datosUsuario.codigoEmpleado,
          setData,
          pageNumber,
          setLoading,
          filterData
        ),
    },
  ];

  const routeMap = dinamicRoutes.reduce((acc, routeObj) => {
    acc[routeObj.route] = routeObj.name;
    return acc;
  }, {});

  const routeNames = dinamicRoutes.map((routeObj) => routeObj.route);
  const [activeRouteName, setActiveRouteName] = useState(routeNames[0]);
  const route = pathname.split("/")[2];
  const index = routeNames.indexOf(route);

  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
    localStorage.removeItem("liquidationID");
  }, []);

  useEffect(() => {
    if (tokenApi) {
      fetchUserAdditionalInfoByTravel(tokenApi, "900605");
      fetchApproverActiveData(
        tokenApi,
        setData,
        dataUser?.datosUsuario?.codigoEmpleado,
        setLoading
      );
      fetchTravelStatusData(tokenApi, setData, setLoading);
    }
  }, [tokenApi]);

  useEffect(() => {
    localStorage.removeItem("isEdit");
  }, []);

  useEffect(() => {
    if (data?.approverActive) {
      const newRouteNames = [...dinamicRoutes].map(
        (routeObj) => routeObj.route
      );
      const index = newRouteNames.indexOf(route);
      if (index !== -1) {
        setActiveRouteName(route);
        dinamicRoutes[index].fetchData();
      } else {
        setActiveRouteName(newRouteNames[0]);
        navigateToUrl(`/solicitudes-auxilio/${newRouteNames[0]}`);
      }
    } else if (data?.approverActive === false) {
      setActiveRouteName(activeRouteName);
      navigateToUrl(`/solicitudes-auxilio/${activeRouteName}`);
    }
  }, [data?.approverActive]);

  useEffect(() => {
    if (tokenApi && index !== -1) {
      dinamicRoutes[index].fetchData();
    }
  }, [tokenApi, pathname, pageNumber]);

  useEffect(() => {
    if (data.requestData.length > 0 && checked) {
      setApproveAll(
        data.requestData.filter(
          (request) => request.fkEstadoViaje === "PENDAPROBO"
        )
      );
    }
  }, [data.requestData.length]);

  useEffect(() => {
    if (data.requestData.length > 0) {
      const approverTripList = data.requestData.filter(
        (trip) => trip.fkEstadoViaje === "PENDAPROBO"
      );
      const approverLegList = data.requestData.filter(
        (trip) => trip.fkEstadoViaje === "LEGALIZENV"
      );
      if (approverLegList.length > 0 && approverTripList.length === 0) {
        setHasMassiveLegalization(true);
      }
    }
  }, [data.requestData.length]);

  useEffect(() => {
    if (clearFilters) {
      setPageNumber(1);
      dinamicRoutes[index].fetchData();
      setClearFilters(false);
    }
  }, [clearFilters]);

  const handleChangeTab = (value) => {
    navigateToUrl(`/solicitudes-auxilio/${value}`);
    setActiveRouteName(value);
    form.reset();
    setPageNumber(1);
  };

  const handleReset = () => {
    form.reset();
    setChecked(false);
    setClearFilters(true);
  };

  const handleFilter = () => {
    setPageNumber(1);
    dinamicRoutes[index].fetchData();
    setChecked(false);
  };

  const loadMore = () => {
    setPageNumber(pageNumber + 1);
  };

  const openModalApproveMassive = () => {
    const isMassiveLegalization = approveAllLeg.length > 0;
    setModalContent({
      content: (
        <Grid ta="center">
          <Grid.Col span={12}>
            <Text>
              {`Usted ha enviado un grupo de ${
                isMassiveLegalization ? "legalizaciones" : "auxilios"
              } para ser aprobados de manera
              masiva. Este proceso no es reversible:`}
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text fw={700} color="#004236">
              ID viaje
            </Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <Text fw={700} color="#004236">
              Estado
            </Text>
          </Grid.Col>
          {(isMassiveLegalization ? approveAllLeg : approveAll).map((item) => (
            <React.Fragment key={item.id}>
              <Grid.Col span={6}>{item.id}</Grid.Col>
              <Grid.Col span={6}>
                {
                  item.listaEstados.find(
                    (status) => item.fkEstadoViaje === status.paramFkEstadoViaje
                  )?.paramNombreEstado
                }
              </Grid.Col>
            </React.Fragment>
          ))}
        </Grid>
      ),
      showButtons: true,
      onClick: () => {
        handleApproveMassive(isMassiveLegalization);
        close();
      },
    });
    open();
  };

  const handleApproveMassive = async (isMassiveLegalization) => {
    const approveAllMap = approveAll.map((item) => ({
      idViaje: item.id,
      mensaje: "string",
      procesar: true,
    }));

    const approveAllLegMap = approveAllLeg.map((item) => ({
      id: item.liquidadorIdLiquidacion,
      requestAprobarLegalizacion: {
        idViaje: item.id,
        idAprobador: dataUser?.datosUsuario?.codigoEmpleado,
        nombreAprobador: dataUser?.datosUsuario?.nombre,
      },
    }));

    try {
      setLoading(true);
      const response = await (isMassiveLegalization
        ? fetchTravelRequestApproveLegMassive(tokenApi, approveAllLegMap)
        : fetchTravelRequestApproveTripMasive(tokenApi, {
            viajes: approveAllMap,
            codAprobador: dataUser?.datosUsuario?.codigoEmpleado,
            nombreAprobador: dataUser?.datosUsuario?.nombre,
            idProcesoMasivo: 0,
          }));
      if (response.status === 200) {
        open();
        setModalContent({
          content: `${
            isMassiveLegalization ? "Las legalizaciones" : "Los auxilios"
          } han sido ${
            isMassiveLegalization ? "aprobadas" : "aprobados"
          } exitosamente`,
          showButtons: false,
          onClick: () => {},
        });
        setTimeout(() => {
          dinamicRoutes?.[0]?.fetchData();
          close();
          setApproveAll([]);
        }, 2000);
      }
    } catch (error) {
      open();
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => {},
      });
    } finally {
      setLoading(false);
    }
  };

  const topFilterData = [
    {
      sm: 6,
      md: 2,
      lg: 2,
      inputType: "textInput",
      label: "Filtrar por ID",
      placeHolder: "Digite un ID",
      value: "idFilter",
      form: form.getInputProps("idFilter"),
    },
    {
      sm: 6,
      md: 2,
      lg: 2,
      inputType: "textInput",
      label: "Filtrar Por Registro",
      placeHolder: "Digite un registro",
      value: "idTraveler",
      form: form.getInputProps("idTraveler"),
    },
    {
      sm: 6,
      md: "auto",
      lg: "auto",
      inputType: "multiSelect",
      label: "Filtrar por estado",
      placeHolder: "Seleccione un estado",
      value: "statusFilter",
      disableSelectedItemFiltering: true,
      rightSection: <LuChevronDown size="1rem" color="#004236" />,
      data: data.status,
      form: form.getInputProps("statusFilter"),
    },
    {
      sm: 6,
      md: 2,
      lg: 2,
      inputType: "button",
      title: "Filtrar",
      onClick: handleFilter,
    },
    {
      sm: 6,
      md: 2,
      lg: 2,
      inputType: "button",
      title: "Limpiar Filtro",
      outline: true,
      onClick: handleReset,
    },
  ];

  const tabsProps = {
    activeRouteName,
    handleChangeTab,
    routeNames,
    classes,
    routeMap,
  };

  const topFilterSectionProps = {
    activeRouteName,
    checked,
    setChecked,
    setApproveAll,
    data,
    classes,
    approveAll,
    openModalApproveMassive,
    WCInlineInputFilter,
    opened,
    topFilterData,
    toggle,
    hasMassiveLegalization,
    checkedLeg,
    setCheckedLeg,
    setApproveAllLeg,
    approveAllLeg,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return isAuthenticated ? (
    <>
      <ModalComponent opened={openedError} onClose={close} title="Alerta">
        <Flex direction="column" gap={12}>
          {typeof modalContent?.content === "string" ? (
            <Text>{modalContent?.content}</Text>
          ) : (
            modalContent?.content
          )}
        </Flex>
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={() => modalContent.onClick()}
              fullWidth
              className={classes.button}
            >
              Aceptar
            </Button>
            <Button onClick={close} fullWidth className={classes.buttonOutline}>
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>

      <Tabs {...tabsProps} />

      <TopFilterSection {...topFilterSectionProps} />
      <Box maw={{ xs: "98%", md: "90%", lg: "80%" }} mx="auto" py="16px">
        {!!data.requestData.length ? (
          <>
            <OutpatientcareRequestCardDetail
              data={data.requestData}
              path={route}
              tokenApi={tokenApi}
              dinamicRoutes={() => dinamicRoutes[index].fetchData()}
              setApproveAll={setApproveAll}
              approveAll={approveAll}
              hasMassiveLegalization={hasMassiveLegalization}
              setApproveAllLeg={setApproveAllLeg}
              approveAllLeg={approveAllLeg}
            />

            <Center mt={12}>
              <Button className={classes.button} onClick={loadMore}>
                Cargar más
              </Button>
            </Center>
          </>
        ) : (
          <Grid>
            <Grid.Col xs={12}>
              <GlobalCard>
                <Flex
                  gap={12}
                  justify="center"
                  align="center"
                  direction="column"
                  sx={{ height: isSm ? "auto" : "224px" }}
                >
                  <Text fw={700} color="#004236" ta="center">
                    No hay solicitudes.
                  </Text>
                </Flex>
              </GlobalCard>
            </Grid.Col>
          </Grid>
        )}
      </Box>
    </>
  ) : null;
};
