import React, { useState } from "react";
import {
  useWindowSize,
  GlobalCard,
  useStyles,
  ModalComponent,
} from "@mfe/js-common-ave-uiutils";
import {
  Flex,
  Text,
  Button,
  Radio,
  Textarea,
  FileInput,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { FaUpload } from "react-icons/fa";

import {
  formatter,
  fetchCardTravel,
  parseDateLocaleFormat,
  fetchLegalizationApprove,
  fetchLegalizationReject,
  fetchNoTripWithoutLegalization,
  fetchLegalizationConditionServices,
  fetchTripWithoutLegalization,
  fetchTravelRequestApproveTrip,
  fetchTraveLiquidatorDelete,
  fetchTravelRequestRejectTrip,
  filterByBeneficiaryType,
  fetchPSEVariable,
  fetchUploadBankSupports,
} from "@mfe/ts-common-ave-utilitaries";
import { navigateToUrl } from "single-spa";
import { BankSupport } from "../components/bankSupport.component";
import CardDetails from "../components/cardDetails.component";

const RadioGroupWithState = ({
  open,
  close,
  item,
  setLoading,
  tokenApi,
  setModalContent,
  openModal,
  closeModal,
  dinamicRoutes,
}) => {
  const { classes } = useStyles();

  const [confirmTravel, setConfirmTravel] = useState("true");
  const [requireModify, setRequireModify] = useState("false");
  const [obsAnulacion, setObsAnulacion] = useState("");
  const [cardTravel, setCardTravel] = useState({});

  const dataUser = JSON.parse(localStorage.getItem("userData") || "{}");

  const trip = {
    idViaje: item?.id?.toString(),
    usuarioLogueado: dataUser?.datosUsuario?.codigoEmpleado,
    obsAnulacion: obsAnulacion,
    aplicaSegmentos: true,
    segmentoViaje: 0,
    numLiquidacion: item?.liquidadorIdLiquidacion || 0,
    ultimoSegmento: true,
    validaSoportesBpo:
      item?.serviciosViaje?.length > 0 &&
      !item?.listaDestinos?.[0]?.auxilioMedico?.EsUrgencia,
  };

  const noTrip = async () => {
    try {
      setLoading(true);
      const { data } = await fetchNoTripWithoutLegalization(tokenApi, trip);
      if (data?.estado === true) {
        navigateToDetail();
      } else {
        close();
        setModalContent({
          content: data?.mensaje,
          showButtons: false,
          onClick: () => {},
        });
        openModal();
      }
    } catch (error) {
      close();
      setModalContent({
        content: "Ocurrió un error, por favor inténtelo nuevamente",
        showButtons: false,
        onClick: () => {},
      });
      openModal();
    } finally {
      setLoading(false);
    }
  };

  const withTrip = async () => {
    try {
      setLoading(true);
      if (
        item?.serviciosViaje?.length > 0 &&
        !item?.listaDestinos?.[0]?.auxilioMedico?.EsUrgencia
      ) {
        const { data } = await fetchLegalizationConditionServices(
          tokenApi,
          item.id
        );
        if (data?.data?.soportesCompletosBpo) {
          setLoading(true);
          if (item?.tieneOtrosGastos) {
            navidateToLiquidation();
          } else {
            await fetchCardTravel(tokenApi, cardTravel);
            await fetchTripWithoutLegalization(tokenApi, trip);
            navigateToDetail();
          }
        } else {
          close();
          setModalContent({
            content: data?.data?.detSoportesFaltantes,
            showButtons: false,
            onClick: () => {},
          });
          openModal();
        }
      } else {
        setLoading(true);
        if (item?.tieneOtrosGastos) {
          navidateToLiquidation();
        } else {
          await fetchCardTravel(tokenApi, cardTravel);
          await fetchTripWithoutLegalization(tokenApi, trip);
          navigateToDetail();
        }
      }
    } catch (error) {
      close();
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => {},
      });
      openModal();
    } finally {
      setLoading(false);
    }
  };

  const navigateToDetail = () => {
    localStorage.setItem("liquidationID", item.id);
    navigateToUrl("/detalle-legalizacion");
  };

  const handleCloseTrip = () => {
    if (confirmTravel === "true") {
      withTrip();
    } else {
      noTrip();
    }
  };

  const handleCloseModal = () => {
    close();
    setConfirmTravel("true");
    setRequireModify("false");
  };

  const attachCardAux = (fileData) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onload = () => {
        let base = reader.result as string;
        base = base.split(",")[1];
        resolve(base);
      };
      reader.onerror = reject;
    });
  };

  const handleAttachCardAux = async (fileData) => {
    const result = await attachCardAux(fileData);
    const rest = {
      idViaje: item.id,
      codEmpleado: item.funcionariosIdViajero,
      nombre: fileData.name,
      extension: fileData.type.split("/")[1],
      archivo: result,
      validar: false,
    };
    setCardTravel(rest);
  };

  const navidateToLiquidation = () => {
    localStorage.setItem("liquidationID", item.id);
    navigateToUrl("/legalizacion");
  };
  return (
    <ModalComponent opened={open} onClose={handleCloseModal}>
      <Text>
        Para continuar con la finalización, confirma haber realizado el auxilio?
      </Text>
      <Radio.Group value={confirmTravel} onChange={setConfirmTravel}>
        <Flex align="center" gap={12}>
          <Radio value={"true"} label="Sí" className={classes.radioButton} />
          <Radio value={"false"} label="No" className={classes.radioButton} />
        </Flex>
      </Radio.Group>

      {confirmTravel === "false" && (
        <>
          <Text>Ingrese el motivo por el cual, no se realizó el viaje.</Text>
          <Textarea
            onChange={(e) => setObsAnulacion(e.target.value)}
            my={12}
            minRows={3}
          />
        </>
      )}
      {confirmTravel === "true" && item?.fkEstadoViaje !== "APROBADO" ? (
        <>
          <Text>
            ¿Requiere modificar fechas, adicionar o modificar gastos a su viaje?
          </Text>
          <Radio.Group
            value={requireModify}
            onChange={setRequireModify}
            mt={12}
          >
            <Flex align="center" gap={12}>
              <Radio
                value={"true"}
                label="Sí"
                className={classes.radioButton}
              />
              <Radio
                value={"false"}
                label="No"
                className={classes.radioButton}
              />
            </Flex>
          </Radio.Group>
          {requireModify === "true"
            ? null
            : !item?.tieneOtrosGastos && (
                <>
                  <FileInput
                    mt={10}
                    onChange={(fileData) => handleAttachCardAux(fileData)}
                    label="Adjuntar tarjeta de viaje efectivo de atención"
                    classNames={{
                      input: classes.input,
                      label: classes.labelSecondary,
                    }}
                    icon={<FaUpload size="1rem" />}
                  />
                  <Text size="sm" color="#004236">
                    Seleccionar un archivo con una capacidad maxima de 200 mb
                  </Text>
                </>
              )}
        </>
      ) : (
        confirmTravel !== "false" && (
          <Text>
            El proceso de legalización no puede continuar porque no se ha
            notificado el pago del anticipo en ave.
          </Text>
        )
      )}
      <Flex gap={16} my={12}>
        <Button
          onClick={() =>
            requireModify === "true"
              ? navidateToLiquidation()
              : handleCloseTrip()
          }
          fullWidth
          className={classes.button}
          disabled={
            confirmTravel === "true"
              ? item?.fkEstadoViaje === "APROBADO"
              : false
          }
        >
          Aceptar
        </Button>
        <Button
          onClick={handleCloseModal}
          fullWidth
          className={classes.buttonOutline}
        >
          Cancelar
        </Button>
      </Flex>
    </ModalComponent>
  );
};

const OutpatientcareRequestCardDetail = ({
  data,
  path,
  tokenApi,
  dinamicRoutes,
  setApproveAll,
  approveAll,
  approveAllLeg,
  setApproveAllLeg,
  hasMassiveLegalization,
}) => {
  const { classes } = useStyles();
  const isMd = useWindowSize("md");
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const dataUser = JSON.parse(localStorage.getItem("userData"));
  const [opened, { open, close }] = useDisclosure(false);
  const [openedEnd, { open: openEnd, close: closeEnd }] = useDisclosure(false);
  const [openedSupports, { open: openSupports, close: closeSupports }] =
    useDisclosure(false);
  const [openedReject, { open: openReject, close: closeReject }] =
    useDisclosure(false);
  const [itemModal, setItemModal] = useState(null);
  const [isRejectLegalization, setIsRejectLegalization] = useState(false);
  const [fileSupport, setFileSupport] = useState({
    name: "",
    base: "",
  });

  const form = useForm({
    initialValues: {
      reference: "",
    },
  });

  const [modalContent, setModalContent] = useState({
    content: {},
    showButtons: true,
    onClick: () => {},
  });

  const formRejectTravel = useForm({
    validateInputOnChange: true,
    initialValues: {
      obsAnulacion: "",
    },
    validate: {
      obsAnulacion: (value) =>
        value.length > 2
          ? null
          : "Ingrese el motivo, con al menos 3 caracteres, del rechazo",
    },
  });

  const modalText = {
    rejectTrip: "Rechazar viaje",
    rejectLegalization: "Rechazar legalización",
    successReject: `La legalización se rechazo correctamente.`,
    sendToApprove: `El viaje será enviado a aprobación.`,
    successLegalize: `La legalización se envió a aprobación correctamente.`,
    approveLegalization: `La legalización será aprobada.`,
    successApproveLegalization: `La legalización se aprobó correctamente.`,
    successRejectLegalization: `La legalización se rechazó correctamente.`,
  };

  const handleApproveTrip = async (requestItem) => {
    try {
      setLoading(true);
      await fetchTravelRequestApproveTrip(
        tokenApi,
        requestItem.id,
        requestItem.funcionariosIdAprobador
      );
      close();
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        dinamicRoutes();
      }, 500);
    }
  };

  const handleDeleteLiquidationByID = async (travelId, employeeCode) => {
    try {
      setLoading(true);
      await fetchTraveLiquidatorDelete(tokenApi, travelId, employeeCode);
      setModalContent({
        content: `El auxilio con ID ${travelId} se eliminó correctamente`,
        showButtons: false,
        onClick: () => {},
      });

      open();
    } catch (error) {
      setModalContent({
        content: JSON?.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
      open();
    } finally {
      setLoading(false);
      setTimeout(() => {
        dinamicRoutes();
      }, 3000);
    }
  };

  const handleRejectTrip = async (requestItem) => {
    try {
      setLoading(true);
      fetchTravelRequestRejectTrip(tokenApi, {
        id: requestItem.id,
        codAprobador: requestItem.funcionariosIdAprobador,
        descripcionRechazo: formRejectTravel.values.obsAnulacion,
      });
      handleCloseReject();
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        dinamicRoutes();
      }, 1000);
    }
  };

  const handleOpenReject = (item, isLegalization = false) => {
    const label = `${
      isLegalization ? "La legalización del viaje" : "La solicitud"
    } <b>${
      item.id
    }</b> será rechazada. Ingrese la observación o razón del rechazo.`;
    setModalContent({
      content: label,
      showButtons: true,
      onClick: () => {},
    });
    openReject();
    setItemModal(item);
    setIsRejectLegalization(isLegalization);
  };

  const handleCloseReject = () => {
    closeReject();
    setIsRejectLegalization(false);
    setItemModal(null);
  };

  const handleApproveLegalization = async (requestItem) => {
    const payload = {
      idViaje: requestItem?.id,
      idAprobador: requestItem?.funcionariosIdAprobador,
      nombreAprobador: requestItem?.funcionarioNombreAprobador,
      segmentoViaje: 0,
    };
    try {
      setLoading(true);
      await fetchLegalizationApprove(
        tokenApi,
        payload,
        requestItem?.liquidadorIdLiquidacion
      );
      setModalContent({
        content: modalText["successApproveLegalization"],
        showButtons: false,
        onClick: () => {},
      });
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        dinamicRoutes();
      }, 1000);
    }
  };

  const handleRejectLegalization = async (requestItem) => {
    const payload = {
      idViaje: requestItem?.id,
      idUsuarioTransaccion: requestItem?.funcionariosIdViajero,
      nombreUsuarioTransaccion: requestItem?.funcionariosNombreViajero,
      funcionariosIdAprobador: requestItem?.funcionariosIdAprobador,
      funcionariosIdAprobadorPrevio: requestItem?.funcionariosIdAprobadorPrevio,
      segmentoViaje: 0,
    };
    try {
      setLoading(true);
      await fetchLegalizationReject(
        tokenApi,
        payload,
        requestItem?.liquidadorIdLiquidacion
      );
      setModalContent({
        content: modalText["successRejectLegalization"],
        showButtons: false,
        onClick: () => {},
      });
      open();
      handleCloseReject();
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
      setTimeout(() => {
        dinamicRoutes();
      }, 1000);
    }
  };

  const handleViewDetails = (requestItem) => {
    localStorage.setItem("liquidationID", requestItem.id);
    navigateToUrl("/reserva-detalles");
  };

  const handleLegalizationDetails = (requestItem) => {
    localStorage.setItem("liquidationID", requestItem.id);
    navigateToUrl("/detalle-legalizacion");
  };

  const handleEdit = (requestItem) => {
    localStorage.setItem("liquidationID", requestItem.id);
    localStorage.setItem("isEdit", "true");
    navigateToUrl("/auxilios");
  };

  const handleAction = (modalContent, actionFunction) => {
    open();
    setModalContent({
      content: modalContent,
      showButtons: true,
      onClick: actionFunction,
    });
  };

  const handleOpen = (item) => {
    openEnd();
    setItemModal(item);
  };

  const handleOpenSupport = (item) => {
    openSupports();
    setItemModal(item);
  };

  const handleSubmitSupports = async () => {
    const payload = {
      IdViaje: itemModal?.id,
      NumeroReferencia: form.values.reference,
      DocumentoSoporte: fileSupport.base,
      NombreArchivo: fileSupport.name,
      MontoPagado: itemModal?.totalAveLegCop,
    };
    try {
      setLoading(true);
      const { data } = await fetchUploadBankSupports(tokenApi, payload);
      setModalContent({
        content: data?.message,
        showButtons: false,
        onClick: () => close(),
      });
      open();
    } catch (error) {
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
      open();
    } finally {
      setLoading(false);
      closeSupports();
      setItemModal(null);
      setTimeout(() => {
        dinamicRoutes();
      }, 3000);
    }
  };

  const handlePSE = async (requestItem) => {
    try {
      setLoading(true);
      const { data } = await fetchPSEVariable(tokenApi);
      const url = data?.data?.replace(
        "{{document}}",
        requestItem?.beneficiarios?.find((item) => item?.tipo === "TITULAR")
          ?.numeroDocumento
      );
      window.open(url, "_blank");
    } catch (error) {
      setLoading(false);
      setModalContent({
        content: JSON.parse(error.request.responseText).ExceptionMessage,
        showButtons: false,
        onClick: () => close(),
      });
    } finally {
      setLoading(false);
    }
  };

  const buttonStates = [
    {
      name: "Pago PSE",
      action: (requestItem) => {
        handlePSE(requestItem);
      },
      stateByPath: [
        {
          path: "auxilios-gestionados",
          state: ["ANULFAVORECP", "LEGAPROSEC"],
        },
      ],
      validations: [
        dataUser?.datosUsuario?.permisosUnicos?.includes("Pago PSE"),
      ],
    },
    {
      name: "Soportes Bancarios",
      action: (requestItem) => {
        handleOpenSupport(requestItem);
      },
      stateByPath: [
        {
          path: "auxilios-gestionados",
          state: ["ANULFAVORECP", "LEGAPROSEC"],
        },
      ],
      validations: [
        dataUser?.datosUsuario?.permisosUnicos?.includes("Soportes Bancarios"),
      ],
    },
    {
      name: "Rechazar",
      action: (requestItem) => {
        handleOpenReject(requestItem);
      },
      stateByPath: [
        {
          path: "auxilios-por-aprobar",
          state: ["PENDAPROBO", "PEDNCONFIRMACION", "PRAPROBADO"],
        },
      ],
    },
    {
      name: "Aprobar",
      action: (requestItem) => {
        handleAction(modalText["sendToApprove"], () =>
          handleApproveTrip(requestItem)
        );
      },
      stateByPath: [
        {
          path: "auxilios-por-aprobar",
          state: ["PENDAPROBO", "PEDNCONFIRMACION", "PRAPROBADO"],
        },
      ],
    },
    {
      name: "Ver detalle anticipo",
      action: (requestItem) => handleViewDetails(requestItem),
      stateByPath: [
        {
          path: "auxilios-por-aprobar",
          state: [
            "LEGALIZENV",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
            "ENPROLEGAL",
          ],
        },
        {
          path: "auxilios-gestionados",
          state: [
            "LEGALIZENV",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
            "ENPROLEGAL",
            "LEGAPROSAF",
            "LEGAPROSEC",
          ],
        },
      ],
    },
    {
      name: "Ver detalle legalización",
      action: (requestItem) => handleLegalizationDetails(requestItem),
      stateByPath: [
        {
          path: "auxilios-por-aprobar",
          state: [
            "LEGAPROSNV",
            "LEGAPROSEC",
            "LEGALIZENV",
            "LEGAPROSAF",
            "ENPROLEGAL",
            "CERRADO",
            "ANULFAVORECP",
            "LEGALIZREC",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
          ],
        },
        {
          path: "auxilios-gestionados",
          state: [
            "LEGALIZENV",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
            "ENPROLEGAL",
            "LEGAPROSAF",
            "LEGAPROSEC",
            "LEGALIZREC",
            "ANULFAVORECP",
          ],
        },
      ],
    },
    {
      name: "Rechazar legalización",
      action: (requestItem) => handleOpenReject(requestItem, true),
      stateByPath: [
        {
          path: "auxilios-por-aprobar",
          state: ["LEGALIZENV", "PEDNCONFIRMACIONLEG", "LEGALIZENVPREV"],
        },
        {
          path: "auxilios-gestionados",
          state: ["LEGALIZENV", "PEDNCONFIRMACIONLEG", "LEGALIZENVPREV"],
        },
      ],
    },
    {
      name: "Aprobar legalización",
      action: (requestItem) =>
        handleAction(modalText["approveLegalization"], () =>
          handleApproveLegalization(requestItem)
        ),
      stateByPath: [
        {
          path: "auxilios-por-aprobar",
          state: ["LEGALIZENV", "PEDNCONFIRMACIONLEG", "LEGALIZENVPREV"],
        },
        {
          path: "auxilios-gestionados",
          state: ["LEGALIZENV", "PEDNCONFIRMACIONLEG", "LEGALIZENVPREV"],
        },
      ],
    },
    {
      name: "Ver detalle",
      action: (requestItem) => handleViewDetails(requestItem),
      stateByPath: [
        {
          path: "auxilios-por-aprobar",
          state: [
            "PENDAPROBO",
            "PEDNCONFIRMACION",
            "PRAPROBADO",
            "APROPAGSAP",
            "CONFIRPAGSAP",
            "APROBADO",
            "PEDNCONFIRMACIONLEG",
            "LEGALIZENVPREV",
          ],
        },

        {
          path: "auxilios-gestionados",
          state: [
            "GUARDADO",
            "RECHAZADO",
            "APROBADO",
            "APROPAGSAP",
            "PENDAPROBO",
            "APROSINPAGSAP",
          ],
        },
      ],
    },
    {
      name: "Finalizar Auxilio",
      action: (requestItem) => handleOpen(requestItem),
      stateByPath: [
        {
          path: "auxilios-gestionados",
          state: ["APROBADO", "APROPAGSAP", "APROSINPAGSAP"],
        },
      ],
    },
    {
      name: "Eliminar",
      action: (requestItem) =>
        handleDeleteLiquidationByID(
          requestItem.id,
          dataUser?.datosUsuario?.codigoEmpleado
        ),
      stateByPath: [
        {
          path: "auxilios-gestionados",
          state: ["GUARDADO", "RECHAZADO"],
        },
      ],
    },
    {
      name: "Editar",
      action: (requestItem) => handleEdit(requestItem),
      stateByPath: [
        {
          path: "auxilios-gestionados",
          state: ["GUARDADO", "RECHAZADO"],
        },
      ],
    },
  ];

  function filterButtonStates(path, state) {
    return buttonStates.filter((button) =>
      button.stateByPath.some(
        (stateObj) => stateObj.path === path && stateObj.state.includes(state)
      )
    );
  }

  const handleAttachSupport = (value) => {
    const reader = new FileReader();
    reader.readAsDataURL(value);
    reader.onload = () => {
      const base = reader.result as string;
      setFileSupport({
        name: value.name,
        base: base.split(",")[1],
      });
    };
  };

  const cardDetailsProps = {
    data,
    GlobalCard,
    isMd,
    showDetails,
    setShowDetails,
    filterByBeneficiaryType,
    classes,
    utils: { formatter, parseDateLocaleFormat },
    approveAll,
    setApproveAll,
    filterButtonStates,
    path,
    approveAllLeg,
    setApproveAllLeg,
    hasMassiveLegalization,
  };

  if (loading) {
    return (
      <LoadingOverlay
        visible={loading}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return (
    <>
      <RadioGroupWithState
        setModalContent={setModalContent}
        setLoading={setLoading}
        tokenApi={tokenApi}
        close={closeEnd}
        open={openedEnd}
        item={itemModal}
        openModal={open}
        closeModal={close}
        dinamicRoutes={dinamicRoutes}
      />
      <ModalComponent opened={opened} onClose={close} title="Alerta">
        {modalContent.content}
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={() => modalContent.onClick()}
              fullWidth
              className={classes.button}
            >
              Aceptar
            </Button>
            <Button onClick={close} fullWidth className={classes.buttonOutline}>
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>
      <BankSupport
        opened={openedSupports}
        close={closeSupports}
        form={form}
        handleSubmitSupports={handleSubmitSupports}
        item={itemModal}
        ModalComponent={ModalComponent}
        classes={classes}
        handleAttachSupport={handleAttachSupport}
        utils={{ formatter }}
      />
      <ModalComponent
        opened={openedReject}
        onClose={handleCloseReject}
        title="Alerta"
      >
        <div dangerouslySetInnerHTML={{ __html: modalContent.content }} />
        <Textarea
          value={formRejectTravel.values.obsAnulacion}
          onChange={(e) =>
            formRejectTravel.setFieldValue("obsAnulacion", e.target.value)
          }
          label="Observación"
          placeholder="Escriba aquí su observación"
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          mt={12}
        />
        {modalContent.showButtons && (
          <Flex gap={16} my={12}>
            <Button
              onClick={() =>
                !isRejectLegalization
                  ? handleRejectTrip(itemModal)
                  : handleRejectLegalization(itemModal)
              }
              fullWidth
              className={classes.button}
              disabled={!formRejectTravel.isValid()}
            >
              Aceptar
            </Button>
            <Button
              onClick={handleCloseReject}
              fullWidth
              className={classes.buttonOutline}
            >
              Cancelar
            </Button>
          </Flex>
        )}
      </ModalComponent>
      <CardDetails {...cardDetailsProps} />
    </>
  );
};

export default OutpatientcareRequestCardDetail;
