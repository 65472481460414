import { Box, Tabs as T } from "@mantine/core";

const Tabs = ({
  activeRouteName,
  handleChangeTab,
  routeNames,
  classes,
  routeMap,
}) => {
  return (
    <Box
      maw={{ xs: "98%", md: "90%", lg: "80%" }}
      mt="3rem"
      mx="auto"
      py="16px"
    >
      <T
        keepMounted={false}
        value={activeRouteName}
        onTabChange={(value) => handleChangeTab(value)}
      >
        <T.List grow className={classes.tabsList}>
          {routeNames.map((route, index) => (
            <T.Tab key={index} className={classes.tab} value={route}>
              {routeMap[route]}
            </T.Tab>
          ))}
        </T.List>
      </T>
    </Box>
  );
};

export default Tabs;
