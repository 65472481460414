import React from "react";
import {
  Flex,
  Text,
  Badge,
  Grid,
  TextInput,
  Checkbox,
  Button,
  Box,
  Textarea,
} from "@mantine/core";
import { BiSolidPlusCircle, BiSolidMinusCircle } from "react-icons/bi";

const CardDetails = ({
  data,
  GlobalCard,
  isMd,
  showDetails,
  setShowDetails,
  filterByBeneficiaryType,
  classes,
  utils,
  approveAll,
  setApproveAll,
  filterButtonStates,
  path,
  hasMassiveLegalization,
  approveAllLeg,
  setApproveAllLeg,
}) => {
  const renderCity = (city, label) => {
    return (
      <Grid.Col span={6}>
        <TextInput
          label={label}
          value={city}
          classNames={{
            label: classes.labelSecondary,
            input: classes.input,
          }}
          disabled={true}
        />
      </Grid.Col>
    );
  };
  return (
    <>
      {data?.map((requestItem, index) => (
        <GlobalCard
          key={index}
          styles={{
            position: "relative",
            marginBottom: "36px",
          }}
        >
          <Flex
            justify="center"
            align="center"
            gap="4px"
            sx={(theme) => ({
              [theme.fn.smallerThan("md")]: {
                paddingTop: "20px",
                flexDirection: "column",
              },
            })}
            mb={12}
          >
            <Flex align="center" gap={4}>
              <Text color="#004236" fw={700} fs="md">
                ID Viaje: {requestItem?.id}
              </Text>
              {isMd &&
                (showDetails ? (
                  <BiSolidMinusCircle
                    size="1.25rem"
                    style={{
                      flexShrink: 0,
                      color: "#cbd300",
                    }}
                    onClick={() => setShowDetails(false)}
                    data-testid="hide-details-button"
                  />
                ) : (
                  <BiSolidPlusCircle
                    size="1.25rem"
                    style={{
                      flexShrink: 0,
                      color: "#cbd300",
                    }}
                    onClick={() => setShowDetails(true)}
                    data-testid="show-details-button"
                  />
                ))}
            </Flex>
            <Badge
              sx={{
                backgroundColor: "#cbd300",
                color: "#004236",
              }}
            >
              {requestItem.listaEstados?.find(
                (status) =>
                  status?.paramFkEstadoViaje === requestItem?.fkEstadoViaje
              )?.paramNombreEstado || "Sin Estado"}
            </Badge>
          </Flex>

          {(isMd ? showDetails : true) && (
            <Grid align="center">
              <Grid.Col span={6}>
                <TextInput
                  label="Nombre del titular"
                  value={`${filterByBeneficiaryType(
                    requestItem?.beneficiarios,
                    "TITULAR",
                    "nombres"
                  )} ${filterByBeneficiaryType(
                    requestItem?.beneficiarios,
                    "TITULAR",
                    "apellidos"
                  )}`}
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Nombre del beneficiario"
                  value={`${filterByBeneficiaryType(
                    requestItem?.beneficiarios,
                    "BENEFICIARIO",
                    "nombres"
                  )} ${filterByBeneficiaryType(
                    requestItem?.beneficiarios,
                    "BENEFICIARIO",
                    "apellidos"
                  )}`}
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                />
              </Grid.Col>

              {[requestItem?.listaDestinos?.[0]].map((city, cityIndex) => (
                <React.Fragment key={cityIndex}>
                  {renderCity(city?.nombreCiudadOrigen, "Ciudad Origen")}
                  {renderCity(city?.nombreCiudadDestino, "Ciudad Destino")}
                </React.Fragment>
              ))}
              <Grid.Col xs={12} md={4}>
                <TextInput
                  label="Numero de Referencia"
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                  value={
                    requestItem?.listaDestinos?.[0]?.auxilioMedico?.NumeroRef
                  }
                />
              </Grid.Col>

              <Grid.Col xs={12} md={4}>
                <TextInput
                  label="Fecha inicio"
                  value={utils.parseDateLocaleFormat(requestItem?.fechaInicio)}
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                />
              </Grid.Col>
              <Grid.Col xs={12} md={4}>
                <TextInput
                  label="Fecha Fin"
                  value={utils.parseDateLocaleFormat(requestItem?.fechaFin)}
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Textarea
                  label="Objetivo del viaje"
                  autosize
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                  value={requestItem?.descripcion}
                  styles={{
                    input: { paddingBlock: "0.4rem" },
                  }}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  label="Valor Total"
                  classNames={{
                    label: classes.labelSecondary,
                    input: classes.input,
                  }}
                  disabled={true}
                  value={utils.formatter.format(
                    requestItem?.anticipoViaje[0]?.totalConServiciosCop
                  )}
                />
              </Grid.Col>
              {requestItem.fkEstadoViaje === "LEGALIZENV" &&
                hasMassiveLegalization && (
                  <Grid.Col xs={12} md={6} sx={{ alignSelf: "center" }}>
                    <Checkbox
                      checked={approveAllLeg.some(
                        (item) => item.id === requestItem.id
                      )}
                      label="Agregar a la legalización masiva"
                      className={classes.checkBox}
                      sx={{ label: { color: "#004236", fontWeight: 700 } }}
                      onChange={() => {
                        if (
                          approveAllLeg.some(
                            (item) => item.id === requestItem.id
                          )
                        ) {
                          setApproveAllLeg(
                            approveAllLeg.filter(
                              (item) => item.id !== requestItem.id
                            )
                          );
                        } else {
                          setApproveAllLeg((prev) => [...prev, requestItem]);
                        }
                      }}
                    />
                  </Grid.Col>
                )}
              {requestItem?.fkEstadoViaje === "PENDAPROBO" && (
                <Grid.Col xs={12} md={6} sx={{ alignSelf: "center" }}>
                  <Checkbox
                    checked={approveAll.some(
                      (item) => item.id === requestItem?.id
                    )}
                    label="Agregar a la aprobación masiva"
                    className={classes.checkBox}
                    sx={{ label: { color: "#004236", fontWeight: 700 } }}
                    onChange={() => {
                      if (
                        approveAll.some((item) => item.id === requestItem?.id)
                      ) {
                        setApproveAll(
                          approveAll.filter(
                            (item) => item.id !== requestItem?.id
                          )
                        );
                      } else {
                        setApproveAll((prev) => [...prev, requestItem]);
                      }
                    }}
                  />
                </Grid.Col>
              )}
              <Grid.Col span={12}>
                <Flex align="center" gap={8} justify="flex-start" wrap="wrap">
                  {filterButtonStates(path, requestItem?.fkEstadoViaje).map(
                    (item, index) => {
                      const shouldRenderButton =
                        !item?.validations ||
                        item.validations.every((val) => val === true);
                      return (
                        shouldRenderButton && (
                          <Button
                            key={index}
                            className={classes.button}
                            onClick={() => item.action(requestItem)}
                            sx={{
                              flex: 1,
                            }}
                          >
                            {item.name}
                          </Button>
                        )
                      );
                    }
                  )}
                </Flex>
              </Grid.Col>

              {requestItem?.esEmergencia && (
                <Box pos="absolute" top={0} left={0} px={6} py={2} bg="#ED7703">
                  <Flex gap={4} c="#fff">
                    <Text fw={500}>Urgencia médica</Text>
                  </Flex>
                </Box>
              )}
            </Grid>
          )}
        </GlobalCard>
      ))}
    </>
  );
};

export default CardDetails;
